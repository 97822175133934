<template>
  <v-card>
    <v-card-text>
      <h2>{{ $t("cookieBanner.title") }}</h2>
      <p class="mb-3">{{ $t("cookieBanner.text") }}</p>
      <a href="https://sencon.nl/privacystatement-sam" target="_blank">{{ $t("cookieBanner.learnMore") }}</a>
    </v-card-text>

    <v-divider />

    <v-card-text>
      <v-checkbox v-model="selectedPreferences" class="cookie-preference-checkbox" :value="CookiePreferences.FUNCTIONAL" readonly disabled>
        <template #label>
          <p class="ml-2">
            <strong class="text-high-emphasis">{{ $t("cookieBanner.necessary.title") }}</strong>
            <br />
            <span>{{ $t("cookieBanner.necessary.text") }}</span>
          </p>
        </template>
      </v-checkbox>

      <v-checkbox v-model="selectedPreferences" class="cookie-preference-checkbox" :value="CookiePreferences.ANALYTICS" readonly disabled>
        <template #label>
          <p class="ml-2">
            <strong class="text-high-emphasis">{{ $t("cookieBanner.analytics.title") }}</strong>
            <br />
            <span>{{ $t("cookieBanner.analytics.text") }}</span>
          </p>
        </template>
      </v-checkbox>

      <v-checkbox v-model="selectedPreferences" class="cookie-preference-checkbox" :value="CookiePreferences.TRACKING">
        <template #label>
          <p class="ml-2">
            <strong class="text-high-emphasis">{{ $t("cookieBanner.tracking.title") }}</strong>
            <br />
            <span>{{ $t("cookieBanner.tracking.text") }}</span>
          </p>
        </template>
      </v-checkbox>
    </v-card-text>

    <v-card-actions class="d-flex">
      <v-btn class="flex-1-1" variant="outlined" @click="emit('accept', [CookiePreferences.FUNCTIONAL, CookiePreferences.ANALYTICS])">{{
        $t("cookieBanner.acceptNecessary")
      }}</v-btn>

      <v-btn
        v-if="selectedPreferences.length === preferenceOptions.length || selectedPreferences.length === 2"
        class="flex-1-1"
        color="primary"
        variant="elevated"
        @click="emit('accept', preferenceOptions)"
        >{{ $t("cookieBanner.acceptAll") }}</v-btn
      >
      <v-btn v-else class="flex-1-1" color="primary" variant="elevated" @click="emit('accept', selectedPreferences)">{{
        $t("cookieBanner.acceptSelected")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { CookiePreferences } from "~~/utils/storage"

const emit = defineEmits<{
  (e: "accept", preferences: Array<string>): void
}>()

const preferenceOptions = [CookiePreferences.FUNCTIONAL, CookiePreferences.ANALYTICS, CookiePreferences.TRACKING]

const selectedPreferences = ref<Array<CookiePreferences>>([CookiePreferences.FUNCTIONAL, CookiePreferences.ANALYTICS])
</script>

<style>
.cookie-preference-checkbox .v-selection-control {
  align-items: flex-start;
}
</style>
